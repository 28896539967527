import {
  computed, ref, set,
} from '@vue/composition-api';
import { useRouter } from '@/@core/utils/utils';
import store from '@/store';

export default () => {
  const { route, router } = useRouter();
  const model = ref({});
  const localQuery = ref({});

  const create = ({
    defaultValue, name, prefix, storage, url, boolean, number,
  }) => computed({
    get() {
      const storeValue = store.getters['cache/get'](prefix, name);
      const queryValue = route.value.query[name];

      if (storage) set(model.value, `${prefix}_${name}`, storeValue || defaultValue);
      if (url && queryValue) set(model.value, `${prefix}_${name}`, queryValue || defaultValue);
      const value = model.value[`${prefix}_${name}`] || defaultValue;

      if (boolean) {
        return String(value) === 'true';
      }
      if (number) {
        return +value;
      }

      return value;
    },
    set(payload) {
      set(model.value, `${prefix}_${name}`, payload);
      if (storage) {
        store.commit('cache/set', { name: `${prefix}_${name}`, value: payload });
      }

      if (url) {
        const { query } = route.value;

        const localModel = {};
        localModel[name] = payload;
        localQuery.value = { ...query, ...localQuery.value, ...localModel };

        router.replace({ query: localQuery.value }).catch(() => {});
      }
    },
  });

  return {
    create,
  };
};
