<template>
  <v-select
    v-bind="$attrs"
    append-to-body
    :calculate-position="withPopper"
    v-on="$listeners"
  />
</template>

<script>
import vSelect from 'vue-select';
import { createPopper } from '@popperjs/core';
import { ref } from '@vue/composition-api';

export default {
  name: 'Select',
  components: {
    vSelect,
  },
  setup() {
    const placement = ref('top');

    const withPopper = (dropdownList, component, { width }) => {
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width;
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.zIndex = 10000;
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.borderRadius = '6px';
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.borderTopStyle = 'none';
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.boxShadow = '0 4px 25px 0 rgb(0 0 0 / 10%)';
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: placement.value,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    };

    return {
      withPopper,
    };
  },
};
</script>

<style lang="scss">
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
